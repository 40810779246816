<template>
  <vx-card>
    <!-- POP UP ASSIGN ITEM TO BRANCH -->

    <template>
      <vs-popup
        classContent="popup-example"
        title="แบ่งสินค้าให้ตามสาขา"
        :active.sync="assignItemPanelActive"
      >
        <!-- ROW OF ASSIGN ITEM TO BRANCH -->
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="12"
          >
            <h4>สินค้ามีทั้งหมด {{ itemAmount }} ชิ้น</h4>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="12"
          >
            <h5 style="color:#7ED321;">มีสินค้ายังไม่ระบุสาขา {{ remainingItems }} ชิ้น</h5>
          </vs-col>
        </vs-row>
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;padding-bottom:40px;"
        >
          <vs-row
            style="margin-top:30px;"
            vs-w="12"
            v-for="(item, index) in loadedBranch"
            :key="index"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              style="padding-right:30px;"
            >{{ item.branchName }}</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >
              <vs-input-number
                style="width:50px; color: red;"
                min="0"
                :max="remainingItems + loadedBranch[index].amount"
                v-model="loadedBranch[index].amount"
              />
            </vs-col>
          </vs-row>
        </div>

        <!--------------------------------------------------------------------->
        <!-- <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div> -->
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="assignItemPanelActive = false"
            type="filled"
          >ยกเลิก</vs-button>
          <vs-button
            @click="saveStock()"
            style="background-color:#7ED321 !important; margin-left:15px;"
            type="filled"
          >บันทึก</vs-button>
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
    </template>
    <div class="grid-layout-container alignment-block" style="vertical-align:text-top ;width:100%;">
      <!--------------------------------------------------------------------->
      <!--  theline  -->
      <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
        <vs-col
          class="px-2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        >
          <h3 style="margin-bottom: 20px;">รายละเอียดสินค้า</h3>
        </vs-col>
        <vs-col
          class="px-2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="1"
          vs-sm="1"
          vs-xs="1"
        ></vs-col>
        <!-- REVIEW A PRODUCT -->
        <vs-col
          class="px-2"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        >

        </vs-col>
        <!-- REVIEW A PRODUCT -->

        <!-- SAVE ITEM -->
        <vs-col
          class="px-2"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        >
          <vs-button
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            style="width:100%; padding-left:0; padding-right:0;"
            color="#7ED321"
            type="filled"
            @click="saveStock()"
            :disabled="!btnActive"
          >
            <span>
              <i
                style="font-size:18px; margin-right:5px;vertical-align:middle;"
                class="material-icons"
              >add_circle_outline</i>
            </span>
            <span style="vertical-align:middle;">บันทึกข้อมูล</span>
          </vs-button>
        </vs-col>
        <!-- SAVE ITEM -->
        <!-- BACK to STOCK -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        >
          <vs-button
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            style="width:100%; padding-left:0; padding-right:0;"
            color="#4a4a4a"
            type="filled"
            @click="$router.push('/backoffice/stock')"
            :disabled="!btnActive"
          >
            <span>
              <i
                style="font-size:18px; margin-right:5px;vertical-align:middle;"
                class="material-icons"
              ></i>
            </span>
            <span style="vertical-align:middle;">กลับสู่หน้าสต็อค</span>
          </vs-button>
        </vs-col>
        <!-- BACK to STOCK -->
      </vs-row>
      <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="11"
          vs-sm="11"
          vs-xs="11"
          class="form-label px-2"
        >
          <div style="width:100%; height:5px; background-color:#50E3C2;"></div>
        </vs-col>
      </vs-row>
      <!--------------------------------------------------------------------->
    </div>

    <div
      class="grid-layout-container alignment-block"
      style="width:100%; padding-top: 10px; padding-bottom:30px;"
    >
      <!--------------------------------------------------------------------->
      <!-- UPLOAD IMAGE -->
      <!--------------------------------------------------------------------->
      <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <!-- UPLOAD IMAGE -->

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="7"
          vs-sm="12"
          vs-xs="12"
        >
          <div class="grid-layout-container alignment-block" style="width:100%;">
            <!--  กลุ่มสินค้าชั่วคราว หรือปกติ -->

            <vs-row v-if="temporary" class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                style="font-weight: bold; font-size:20px;"
                class="form-label px-2"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
              >
                <span style="color:#FF7283">กลุ่มสินค้า :</span>
              </vs-col>
              <vs-col
                style="font-weight: bold; font-size:20px;"
                class="form-label px-2"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="2"
                vs-sm="2"
                vs-xs="2"
              >
                <span style="color:#FF7283">ชั่วคราว</span>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >
                <vs-input class="w-full" name="temporary" v-model="temporary" />
              </vs-col>
            </vs-row>

            <vs-row v-else class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                style="font-weight: bold; font-size:20px;"
                class="form-label px-2"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
              >
                <span style="color:#1EB9AB">กลุ่มสินค้า :</span>
              </vs-col>
              <vs-col
                style="font-weight: bold; font-size:20px;"
                class="form-label px-2"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="2"
                vs-sm="2"
                vs-xs="2"
              >
                <span style="color:#1EB9AB">ปกติ</span>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              ></vs-col>
            </vs-row>

            <!--  ปีชุด -->

            <!--  ชื่อสินค้า -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
                style="font-weight: bold;"
              >ชื่อสินค้า :</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{theItem.name}}</vs-col>
            </vs-row>

            <!--  รหัสสินค้า -->

            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
                style="font-weight: bold;"
              >รหัสสินค้า</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{theItem.code}}</vs-col>
            </vs-row>

            <!--  จำนวน  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                style="font-weight: bold;"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
              >จำนวน</vs-col>
              <vs-col
                v-if="$acl.check('onlyStaff')"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
              >{{ mybranchstock(productId) }}</vs-col>
              <vs-col
                v-if="$acl.check('admin')"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
              >
                <vs-input-number
                  style="margin-left: 40px; width:50px; color: red;"
                  :min="minimumRemaining"
                  v-model="theItem.inStock"
                />
              </vs-col>
              <vs-col
                v-if="$acl.check('admin')"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                style="cursor:pointer;"
              ></vs-col>
            </vs-row>

            <!--  สาขา -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex=start"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2 pt-5"
                style="font-weight: bold;"
              >จำนวนสินค้าในสาขา</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >
                <div
                  class="grid-layout-container alignment-block pl-10"
                  style="vertical-align:text-top ;width:100%;"
                >
                  <vs-row class="pb-3" vs-w="12" v-for="(item, index) in loadedBranch" :key="index">
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="margin-left: -15px;"
                    >{{ item.branchName }}</vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input-number
                        style="width:50px; color: red;"
                        min="0"
                        :max="remainingItems + loadedBranch[index].amount"
                        v-model="loadedBranch[index].amount"
                      />
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>

            <!--  ราคาเช่า / ชิ้น  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
                style="font-weight: bold;"
              >ราคาเช่า / ชิ้น</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                class="form-label px-2"
              >
                <!-- import CurrencyValue from "./Components/CurrencyValue"; -->
                <!--  add to component: {CurrencyValue} -->
                <CurrencyValue class="w-full" v-model="theItem.rentalPrice"></CurrencyValue>
              </vs-col>
            </vs-row>

            <!--  ค่าประกัน / ชิ้น  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
                style="font-weight: bold;"
              >ค่าประกัน / ชิ้น</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                class="form-label px-2"
              >
                <!-- import CurrencyValue from "./Components/CurrencyValue"; -->
                <!--  add to component: {CurrencyValue} -->
                <CurrencyValue class="w-full" v-model="theItem.bail"></CurrencyValue>
              </vs-col>
            </vs-row>

            <vs-row vs-w="12">
              <!-- <canvas
                style="display:none; padding:0 ;"
                id="canvas"
                width="width:330px;"
                height="142"
              ></canvas>-->
              <canvas style="display: none;  padding:0;" id="canvas"></canvas>
              <div
                style="display: none; text-align:center; margin-left:auto; margin-right:auto;"
                id="png-container"
              ></div>
              <div
                style="display: none; text-align:center; margin-left:auto; margin-right:auto;"
                id="tempimage"
              ></div>
            </vs-row>

            <!--  สถานะ  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
                style="font-weight: bold;"
              >สถานะ</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >
                <vs-switch color="success" @change="changeState()" v-model="statusValue">
                  <span slot="on">เปิด</span>
                  <span slot="off">ปิด</span>
                </vs-switch>
              </vs-col>
            </vs-row>

            <!--------------------------------------------------------------------->
            <!--  theline  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <h4>ข้อมูลปรากฏหน้าเว็บ</h4>
              <span
                style="font-size=0.75em; font-size=#777; margin-bottom: 20px;"
              >(ในส่วนของข้อมูลหน้าเว็บจะให้บริการในเฟสต่อไป To be continue . . .)</span>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="10"
                vs-sm="10"
                vs-xs="10"
                class="form-label px-2"
              >
                <div style="width:100%; height:5px; background-color:#50E3C2;"></div>
              </vs-col>
            </vs-row>
            <!--------------------------------------------------------------------->

            <!--  หมวดหมู่สำหรับแสดงหน้าเว็บ  -->
            <!--  Add this to data part  (import vSelect from vue-select) -->
            <!--  :select >>> ...Selected /  :options >>> ...Options -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                style="font-weight:bold;"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
              >หมวดหมู่สำหรับแสดงหน้าเว็บ</vs-col>
              <vs-col
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                class="form-label px-2"
                style="word-wrap: break-word !important;"
              >
                <div
                  @click="selectWebcat(wc.description)"
                  :class="{ smchoose: checkWebcatSelected(wc.description) }"
                  class="sm-btn-empty"
                  style="float:left; width:fit-content; margin-right:5px;  font-size: 14px !important;"
                  v-for="(wc, webcatIndex) in allWebcat"
                  :key="webcatIndex"
                >{{ wc.description }}</div>
                <!-- <v-select
                  style="width: 100%;"
                  v-model="itemtypeonwebSelected"
                  :options="itemtypeonwebOptions"
                  :select="itemtypeonwebSelected"
                ></v-select>-->
              </vs-col>
            </vs-row>

            <!--  แสดงเป็น 8 สินค้าแรก  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                style="font-weight:bold;"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
              >Hot Item</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                class="form-label px-2"
              >
                <ul>
                  <li style="display:inline-block; margin-right: 15px;">
                    <vs-radio v-model="bookmarkedItem" vs-value="ไม่ใช่">ไม่ใช่</vs-radio>
                  </li>
                  <li style="display:inline-block; margin-right: 15px;">
                    <vs-radio v-model="bookmarkedItem" vs-value="ใช่">ใช่</vs-radio>
                  </li>
                </ul>
              </vs-col>
            </vs-row>

            <!--  แสดงเป็น 8 สินค้าแรก  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                style="font-weight:bold;"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
                class="form-label px-2"
              >แสดงบนหน้าเว็บ</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                class="form-label px-2"
              >
                <ul>
                  <li style="display:inline-block; margin-right: 15px;">
                    <vs-radio v-model="webitem" vs-value="n">ไม่ใช่</vs-radio>
                  </li>
                  <li style="display:inline-block; margin-right: 15px;">
                    <vs-radio v-model="webitem" vs-value="y">ใช่</vs-radio>
                  </li>
                </ul>
              </vs-col>
            </vs-row>

            <!--  web description  -->
            <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
              <vs-col
                style="font-weight:bold;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                class="form-label px-2"
              >รายละเอียดสินค้า</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                class="form-label px-2"
              >
                <!-- v-model="note.content" -->
                <tiptap-editor v-model="webDescription"></tiptap-editor>
                <!-- <tiptap-editor v-model="note.content"></tiptap-editor> -->
                <!-- <div class="editor">
                  <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                    <div class="menubar">
                      <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <b>B</b>
                      </button>

                      <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <b>I</b>
                      </button>

                      <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <b>U</b>
                      </button>

                      <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                        @click="commands.heading({ level: 1 })"
                      >H1</button>

                      <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                        @click="commands.heading({ level: 2 })"
                      >H2</button>

                      <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                        @click="commands.heading({ level: 3 })"
                      >H3</button>

                      <button class="menubar__button" @click="commands.horizontal_rule">line</button>

                      <button class="menubar__button" @click="commands.undo">Undo</button>

                      <button class="menubar__button" @click="commands.redo">Redo</button>
                    </div>
                  </editor-menu-bar>

                  <editor-content class="editor__content" :editor="editor" />
                </div>-->

                <!-- <editor-content :editor="editor" /> -->
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
      <!--------------------------------------------------------------------->
      <!--  ปุ่มด้านล่าง  -->
      <!--------------------------------------------------------------------->

      <div
        class="grid-layout-container alignment-block"
        style="vertical-align:text-top ;width:100%;"
      >
        <!--------------------------------------------------------------------->
        <!--  theline  -->
        <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="11"
            vs-sm="11"
            vs-xs="11"
            class="form-label px-2"
          >
            <div style="width:100%; height:5px; background-color:#50E3C2;"></div>
          </vs-col>
        </vs-row>
        <!--  /theline  -->
        <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <!-- <h3 style="margin-bottom: 20px;">รายละเอียดสินค้า</h3> -->
          </vs-col>
          <vs-col
            class="px-2"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="1"
            vs-sm="1"
            vs-xs="1"
          ></vs-col>
          <!-- REVIEW A PRODUCT -->
          <vs-col
            class="px-2"
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="2"
            vs-sm="2"
            vs-xs="2"
          >
            <!-- <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0;"
              color="#FF7283"
              type="filled"
              @click="$router.push('/backoffice/product-review/' + pid)"
              :disabled="!btnActive"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                ></i>
              </span>
              <span style="vertical-align:middle;">เพิ่มรูปรีวิวสินค้า</span>
            </vs-button>-->
          </vs-col>
          <!-- REVIEW A PRODUCT -->

          <!-- SAVE ITEM -->
          <vs-col
            class="px-2"
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="2"
            vs-sm="2"
            vs-xs="2"
          >
            <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0;"
              color="#7ED321"
              type="filled"
              @click="saveStock()"
              :disabled="!btnActive"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >add_circle_outline</i>
              </span>
              <span style="vertical-align:middle;">บันทึกข้อมูล</span>
            </vs-button>
          </vs-col>
          <!-- SAVE ITEM -->
          <!-- BACK to STOCK -->
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="2"
            vs-sm="2"
            vs-xs="2"
          >
            <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0;"
              color="#4a4a4a"
              type="filled"
              @click="$router.push('/backoffice/stock')"
              :disabled="!btnActive"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                ></i>
              </span>
              <span style="vertical-align:middle;">กลับสู่หน้าสต็อค</span>
            </vs-button>
          </vs-col>
          <!-- BACK to STOCK -->
        </vs-row>

        <!--------------------------------------------------------------------->
      </div>
      <!-------------- DIV BLOCK ------------------------------------------------------->
    </div>
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import vSelect from "vue-select"; // "v-select": vSelect  @Component{}
import VueBarcode from "vue-barcode";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
import TiptapEditor from "./Tiptap";

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// ClassicEditor.create(document.querySelector("#editor"), {
//   plugins: [CKFinder],
//   toolbar: ["imageUpload"],
//   ckfinder: {
//     // Upload the images to the server using the CKFinder QuickUpload command.
//     uploadUrl: this.$store.state.imageURL
//   }
// });

export default {
  mixins: [shapeFormat],
  props: ["msg"],
  data() {
    return {
      theItem: {},
      allWebcat: [],
      webcatSelected: [],
      webcat: [],
      webitem: "n",
      currentUser: "guest",
      webDescription: "",
      checkChange: 0,
      backup3digit: "000",
      newRecord: true,
      delayInMilliseconds: 1000,
      color: "",
      statusValue: true,
      thestock: [],
      branches: [],
      loadedBranch: [],
      btnActive: true,
      active: false,
      pid: "",
      userImage: "",
      uploadurl: "",
      temporary: false,
      // loadedBranch: [
      //   { brachCode: "001", branchName: "สาขาลาดพร้าว", amount: 0 },
      //   { branchCode: "002", branchName: "สาขาเมืองทองธานี", amount: 0 }
      // ],
      minimumRemaining: 0,
      remainingItems: 0,
      assignItemPanelActive: false,
      dropzoneOptions: {},

      data_object: { imageName: "../asset/image.png" },
      itemName: "Item Name",
      itemCode3Digit: "001",
      itemCode3DigitTemp: "001",
      itemCode: "",
      rentalPrice: 0,
      bail: 0,
      itemAmount: 1,
      gender: "W",
      categories: [],
      categorySelect: { label: "ไม่มี", value: "ไม่มี" },
      categoryOption: [],
      colors: [],
      colorSelect: { label: "ไม่มี", value: "ไม่มี" },
      colorOption: [],
      textures: [],
      // textureSelect: { label: "ไม่มี", value: "ไม่มี" },
      // textureOption: [],

      textureSelect: { label: "ผ้าฝ้าย", value: "C" },
      textureOption: [],
      sizes: [],
      sizeSelected: "S",
      sizeOptions: [],
      thisYear: 4,
      yearthSelected: { label: "ปีที่ 1", value: 1 },
      yearthOptions: [
        { label: "ปีที่ 1", value: 1 },
        { label: "ปีที่ 2", value: 2 },
        { label: "ปีที่ 3", value: 3 },
        { label: "ปีที่ 4", value: 4 }
      ],
      firstYear: "2015-01-01",
      statusSelected: "เปิด",
      statusOptions: ["เปิด", "ปิด"],
      itemtypeonwebSelected: "ผู้หญิง",
      itemtypeonwebOptions: ["ผู้ชาย", "ผู้หญิง", "เด็ก"],
      bookmarkedItem: "ไม่ใช่",
      base64Barcode: "",
      mainImage: "",
      barcodeImage: "",
      recordStatus: "",
      images: [],
      stockNum: []
    };
  },
  methods: {
    async getAllWebcat() {
      this.allWebcat = await this.getOne("/api/webcategory");
    },
    checkWebcatSelected(wc_desc) {
      if (
        this.itemtypeonwebSelected === "" ||
        this.itemtypeonwebSelected === null ||
        this.itemtypeonwebSelected === undefined
      ) {
        return false;
      }
      var arr = this.itemtypeonwebSelected.split("|");

      return arr.includes(wc_desc);
    },
    selectWebcat(wc_desc) {
      // Validate wc_desc
      if (wc_desc === "" || wc_desc === null || wc_desc === undefined) {
        return;
      }

      //

      if (
        this.itemtypeonwebSelected === "" ||
        this.itemtypeonwebSelected === null ||
        this.itemtypeonwebSelected === undefined
      ) {
        this.itemtypeonwebSelected = wc_desc;
      } else {
        var arr = this.itemtypeonwebSelected.split("|");
        if (arr.includes(wc_desc)) {
          var ind = arr.indexOf(wc_desc);
          if (ind > -1) {
            arr.splice(ind, 1);
            this.itemtypeonwebSelected = arr.join("|");
          }
        } else {
          this.itemtypeonwebSelected =
            this.itemtypeonwebSelected + "|" + wc_desc;
        }
      }

    },
    mybranchstock(productId) {
      var st = this.thestock.find(
        x =>
          x.productItemId === productId &&
          x.branchId === this.$store.state.auth.mybranchid
      );
      if (st === null || st === undefined || st === "") {
        return 0;
      } else {
        return st.inStock;
      }
    },
    openLoading() {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 500);
    },

    // async loadStock() {
    //   try {
    //     const response = await this.$http.get(
    //       this.$store.state.apiURL +
    //         "/api/productitem/" +
    //         this.$route.params.productid
    //     );
    //     this.noticeSuccess("ทำการบันทึกสำเร็จ");
    //     this.stockNum = response.data;
    //     console.log("response", response.data);
    //   } catch (error) {
    //     this.noticeSuccess(error);
    //     console.log("error", error);
    //     // appropriately handle the error
    //   }
    // },

    changeState() {
      //  console.log("Status value >>> ", this.statusValue);
    },
    async saveStock() {
      this.$vs.loading({ type: "radius" });
      var stockSave = [];
      for (var i = 0; i < this.loadedBranch.length; i++) {
        var item = {
          branchId: this.loadedBranch[i].branchId,
          productItemId: this.loadedBranch[i].productItemId,
          inStock: this.loadedBranch[i].amount
        };
        stockSave.push(item);
      }

      // console.log("TRY TO SAVE stockSave >>> ", stockSave);
      // var response = null;
      try {
        await this.$http.put(
          this.$store.state.apiURL +
            "/api/stock/" +
            this.$route.params.productid,
          stockSave,
          this.$store.state.auth.apiHeader
        );
        await this.saveItemOnlyInfo();
        // this.noticeSuccess("ทำการบันทึกสำเร็จ");

        // console.log("response", response.data);
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 500);
        // response = null;
        this.noticeError("กรุณาตรวจสอบข้อมูบอีกครั้ง");
        // console.log("error", error);
        // appropriately handle the error
      }
      setTimeout(() => {
        this.$vs.loading.close();
      }, 500);
      // if (response.status !== 200) {
      //   this.assignItemPanelActive = false;
      //   console.log("saveItemOnlyInfo()");

      // }
    },
    async setMainImage(filename) {
      // console.log("@ setMainImage >>> ", filename);

      this.mainImage = filename;
      await this.saveItemOnlyInfo();
    },
    removeItemImage(imageid, indextr) {
      this.$swal({
        title: "ต้องการลบรูปใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรูป"
      }).then(result => {
        if (result.value) {
          this.deleteImage(imageid, indextr);
        }
      });
    },

    deleteImage(imageid, indextr) {
      this.$http
        .delete(
          this.$store.state.apiURL + "/api/itemimage/" + imageid,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          if (response.status === 200) {
            //  console.log("@ Remove IMAGEs ", imageid);

            this.$vs.notify({
              title: "ลบเรียบร้อยแล้ว",
              position: "top-center",
              text: "ลบเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            this.images.splice(indextr, 1);
            // console.log("response.data ", response.data);
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "ลบไม่สำเร็จ",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },

    async convertToPNG() {
      // BEGIN: CHANGE SVG to PNG
      var barcodeSVG = document.getElementsByClassName(
        "vue-barcode-element"
      )[0];

      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.

      var svgString = new XMLSerializer().serializeToString(barcodeSVG);

      var canvas = document.getElementById("canvas");

      var ctx = await canvas.getContext("2d");

      // console.log(
      //   " barcodeSVG.width >>> ",
      //   barcodeSVG.getBBox().width,
      //   " barcodeSVG.height >>> ",
      //   barcodeSVG.getBBox().height
      // );

      canvas.width = barcodeSVG.getBBox().width;
      canvas.height = barcodeSVG.getBBox().height;

      // canvas.width = 410;
      // canvas.height = 150;

      // canvas.width =  ;
      // canvas.height = barcodeSVG.height;

      // console.log(
      //   "window.innerWidth >>> ",
      //   window.innerWidth,
      //   "canvas.height = window.innerHeight >>> ",
      //   (canvas.height = window.innerHeight)
      // );

      var DOMURL = self.URL || self.webkitURL || self;
      var img = new Image();
      var svg = new Blob([svgString], {
        type: "image/svg+xml;charset=utf-8"
      });
      var url = DOMURL.createObjectURL(svg);

      img.onload = function() {
        ctx.drawImage(img, 0, 0);
        var png = canvas.toDataURL("image/png");
        document.querySelector("#png-container").innerHTML =
          '<img style=" margin-left: auto; margin-right:auto;" id="newimg" src="' +
          png +
          '"/>';
        document.querySelector("#tempimage").innerHTML = png;
        this.base64Barcode = png;
        DOMURL.revokeObjectURL(png);

        // console.log("THE PNG >>> ", png);
      };
      img.src = url;
      // console.log("img.src >>> ", img.src);

      // END: CHANGE SVG to PNG
    },
    async waitforbarcode() {
      setTimeout(
        () => {
          var txt = document.querySelector("#tempimage");
          // var stxt = txt.split(",");
          var st = txt.innerHTML;
          var st_split = st.split(",");
          var base64PNG = st_split[1];

          this.$http
            .post(
              this.$store.state.apiURL +
                "/api/productitem/save-barcode/" +
                this.pid,
              {
                barcodeValue: this.barcodeValue,
                base64Format: base64PNG
              },
              this.$store.state.auth.apiHeader
            )
            .then(response => {
              if (response.status === 200) {
                this.btnActive = !this.btnActive;
              }
            })
            .catch(() => {
              this.btnActive = !this.btnActive;
              //  console.log("err => ", err);
            });
          //do stuff
        },

        1500
      );
    },
    async saveItemOnlyInfo() {
      this.btnActive = !this.btnActive;
      this.$vs.loading();

      var saveres = null;

      try {
        saveres = await this.$http.put(
          this.$store.state.apiURL + "/api/productitem/" + this.pid,
          this.theItem,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        saveres = null;
      }

      if (saveres != null) {
        setTimeout(() => {
          this.btnActive = !this.btnActive;
          this.noticeSuccess("บันทึกสำเร็จ");
          this.$vs.loading.close();
        }, 500);
      } else {
        setTimeout(() => {
          this.btnActive = !this.btnActive;
          this.noticeError("บันทึกไม่สำเร็จ");
          this.$vs.loading.close();
        }, 500);
      }

      // this.waitforbarcode();
    },
    async saveItem() {
      this.btnActive = !this.btnActive;
      this.newRecord = false;
      this.database3Digit = this.item;
      this.$vs.loading({ type: "radius" });

      // await this.convertToPNG();
      // console.log("this.base64Barcode >>> ", this.base64Barcode);

      // await this.$http.post(
      //   this.$store.state.apiURL + "/api/productitem/save-barcode/" + this.pid,
      //   {
      //     barcodeValue: this.barcodeValue,
      //     base64Format: this.base64Barcode
      //   },
      //   this.$store.state.auth.apiHeader
      // );

      var responseProduct = null;
      try {
        responseProduct = await this.$http.put(
          this.$store.state.apiURL + "/api/productitem/" + this.pid,
          this.theItem,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        //  console.log("SAVE ERROR >>> ", err);
        responseProduct = null;
      }

      if (responseProduct !== null) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว");
        }, 2000);
        // this.btnActive = !this.btnActive;
        //  console.log("REPONSE DATA HERE !!!  ", responseProduct.data);
        //  console.log("REPONSE ITEMNAME !!!  ", this.itemName);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
          this.btnActive = !this.btnActive;
          this.noticeError("บันทึกไม่สำเร็จ");
        }, 1000);
      }

      this.waitforbarcode();
    },
    async reloadImage() {
      // RESET ALL IMAGES
      this.images = [];
      // LOAD รูปทั้งหมด
      var imgres = null;
      try {
        imgres = await this.$http.get(
          this.$store.state.apiURL + "/api/itemimage/" + this.pid,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        imgres = null;
      }

      //  console.log("imgres >>> ", imgres);

      if (imgres !== null) {
        {
          for (var i = 0; i < imgres.data.length; i++) {
            var p = await imgres.data[i].fileName;
            // var sp = p.split("src");

            // var path = @sp[1];
            var resp = {
              // itemimage: require("@/assets/images/itemimage/" + p)
              // itemimage: require("@/assets/images/itemimage/" + p),
              itemimage: this.$store.state.imageStore + (await p),
              imageid: await imgres.data[i].itemImageId,
              fileName: await p
            };

            //  console.log("@@@ CREATE ITEM >>> IMAGE RESP >>>> ", resp);

            this.images.push(resp);
          }

          // console.log("this.images >>> ", this.images);
        }
      } else {
        this.noticeError("โหลดรูปภาพไม่สำเร็จ");
      }
    },

    // TRY TO UPLOAD
    uploadComplete(file) {
      console.log(file);
      this.$refs.dropzone.removeAllFiles();
      setTimeout(() => {
        //your code to be executed after 1 second
        this.reloadImage();
      }, this.delayInMilliseconds);

      // location.reload(true);
    },
    removeImage: function() {
      this.userImage = "";
    },
    // TRY TO UPLOAD
    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles();
    },
    assignItemPanel: function(event) {
      event.stopPropagation();
      this.assignItemPanelActive = true;

      var assignedItemsSum = 0;

      for (var i = 0; i < this.loadedBranch.length; i++) {
        assignedItemsSum = assignedItemsSum + this.loadedBranch[i].amount;
      }
      this.remainingItems = this.theItem.inStock - assignedItemsSum;
    },

    successUpload() {
      this.$vs.notify({
        title: "อัพโหลดรูปเรียบร้อยแล้ว",
        position: "top-center",
        text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success"
      });
      //  console.log("SUCCESS UPLOAD");
    },

    getbarcode() {
      var year_th = "";

      if (this.yearthSelected.value < 10) {
        year_th = "0" + this.yearthSelected.value.toString();
      } else {
        year_th = this.yearthSelected.value.toString();
      }

      // console.log("<<<<<<<<< @getbarcode() >>>>>>>>>>>>>>>>");

      // console.log("year_th >>> ", year_th);

      // console.log(
      //   "this.categorySelected.value  >>> ",
      //   this.categorySelect.value
      // );
      // console.log("this.textureSelected.value >>> ", this.textureSelect.value);

      // console.log("this.itemCode3Digit >>> ", this.itemCode3Digit);

      // console.log("this.sizeSelected >>> ", this.sizeSelected);

      return (
        year_th +
        this.categorySelect.value +
        this.textureSelect.value +
        this.gender +
        this.itemCode3Digit +
        this.sizeSelected
      );
    },

    makeSelect(obj) {
      var item = {};
      if (obj) {
        item = {
          label: obj.description,
          value: obj.code
        };
      } else {
        item = {
          label: "ไม่มีข้อมูล",
          value: "ไม่มีข้อมูล"
        };
      }
      return item;
    },
    makeOption(configlist) {
      var list = [];
      for (let i = 0; i < configlist.length; i++) {
        var item = {
          label: configlist[i].description,
          value: configlist[i].code
        };
        list.push(item);
      }

      return list;
    }

    // uploadChange() {
    //   console.log(">>>> UPLOAD CHANGE <<<<<");
    //   var uploads = document.querySelectorAll(".con-input-upload");
    //   var images = document.querySelectorAll(".img-upload");
    //   var i;
    //   // uploads[0].remove();
    //   for (i = 0; i < images.length; i++) {
    //     images[i].style.order = i + 1;
    //   }
    //   console.log(
    //     ">>>> UPLOAD CHANGE <<<<< order >>>> ",
    //     uploads[0].style.order
    //   );
    // }
  },
  watch: {
    bookmarkedItem:function(value) {
      this.theItem.webHotItem = value;
      // console.log('this.theItem.webHotItem >>>> ',value);

    },
    webitem: function(value){
      this.theItem.webItem = value;
      // console.log('this.theItem.webItem >>>> ',value);
    },
    yearthSelected: {
      handler() {
        if (this.checkChange <= 1) {
          this.checkChange = this.checkChange + 1;
        }
        this.$vs.loading({ type: "radius" });
        // LOAD get3digitbyorder
        this.$http
          .get(
            this.$store.state.apiURL +
              "/api/year/get3digitbyorder/" +
              this.yearthSelected.value,
            this.$store.state.auth.apiHeader
          )
          .then(response => {
            // console.log(response.status);
            if (response.status === 200) {
              {
                if (this.checkChange > 1) {
                  this.itemCode3DigitTemp = response.data;
                  this.itemCode3Digit = this.itemCode3DigitTemp;
                }
              }
            }
            setTimeout(() => {
              this.$vs.loading.close();
            }, 500);
          })
          .catch(error => {
            setTimeout(() => {
              this.$vs.notify({
                title: "เกิดข้อผิดพลาดในการทำงาน",
                position: "top-center",
                text: error.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
              this.$vs.loading.close();
            }, 500);
          });
        // console.log(
        //   "this.yearthSelected >>> ",
        //   this.yearthSelected,
        //   " itemCode3Digit >>> ",
        //   this.itemCode3Digit
        // );
      },
      deep: true
    },
    images: {
      handler() {},
      deep: true
    },
    loadedBranch: {
      handler() {
        var assignedItemsSum = 0;
        // SET ZERO
        for (var k = 0; k < this.loadedBranch.length; k++) {
          if(this.loadedBranch[k].amount)
          {
             if(this.loadedBranch[k].amount < 0)
             {
                this.loadedBranch[k].amount = 0
             }
          }

        }

        for (var i = 0; i < this.loadedBranch.length; i++) {
          assignedItemsSum = assignedItemsSum + this.loadedBranch[i].amount;

        }
        this.remainingItems = this.theItem.inStock - assignedItemsSum;
        this.minimumRemaining = assignedItemsSum;

        // console.log('this.loadedBranch >>>>>>> ',this.loadedBranch);

      },
      deep: true
    },
    theItem: {
      handler() {
        var assignedItemsSum = 0;

        for (var i = 0; i < this.loadedBranch.length; i++) {
          assignedItemsSum = assignedItemsSum + this.loadedBranch[i].amount;
        }
        this.remainingItems = this.theItem.inStock - assignedItemsSum;
        this.minimumRemaining = assignedItemsSum;
      },
      deep: true
    },
    itemtypeonwebSelected: function(value){
        this.theItem.webCategory = value;
    }

    // yearthSelected: {
    //   handler() {
    //     console.log("@ WATCH yearthSelected >>> ", this.yearthSelected);

    //     return (
    //       this.yearthSelected.value +
    //       this.categorySelect.value +
    //       this.textureSelect.value +
    //       this.gender +
    //       this.itemCode3Digit +
    //       this.sizeSelected
    //     );
    //   },
    //   deep: true
    // }

    // getbarcodevalue: function() {
    //   var year_th = "";

    //   if (this.latestyear.value < 10) {
    //     year_th = "0" + this.latestyear.value.toString();
    //   } else {
    //     year_th = this.latestyear.value.toString();
    //   }

    //   return (
    //     year_th +
    //     this.categorySelected.value +
    //     this.textureSelected.value +
    //     this.gender +
    //     this.itemCode3Digit +
    //     this.sizeSelected
    //   );
    // }
  },
  created() {
    this.pid = this.$route.params.productid;

    var tk = localStorage.getItem("token");
    var tkpaser = JSON.parse(tk);
    // $#@
    this.uploadurl =
      this.$store.state.apiURL +
      "/api/item-image-upload/" +
      this.$route.params.productid;
    this.dropzoneOptions = {
      url: this.uploadurl,
      maxFilesize: 5, // MB
      headers: {
        Authorization: "bearer " + tkpaser
      }
      // maxFiles: 4,
      // chunking: true,
      // chunkSize: 500, // Bytes
      // thumbnailWidth: 150, // px
      // thumbnailHeight: 150,
      // addRemoveLinks: true
    };
  },

  computed: {
    // loadedBranch: {
    //   get: function() {
    //     console.log(">>>>>>>>>>>>> @LOAD BRANCH <<<<<<<<<<<<");

    //     var loaded = [];
    //     for (var i = 0; i < this.branches.length; i++) {
    //       var st = this.thestock.find(
    //         x => x.branchId === this.branches[i].branchId
    //       );
    //       var li = {
    //         branchId: this.branches[i].branchId,
    //         branchName: this.branches[i].branchName,
    //         productItemId: this.$route.params.productid,
    //         amount: 0
    //       };
    //       if (st) {
    //         li.amount = st.inStock;
    //       }
    //       loaded.push(li);
    //     }

    //     console.log("loadedBranch loaded >>> ", loaded);

    //     return loaded;
    //   },
    //   set: function() {}
    // },
    // getYearth: {
    //   get: function() {
    //     var year_th = "";

    //     if (this.latestyear.value < 10) {
    //       year_th = "0" + this.latestyear.value.toString();
    //     } else {
    //       year_th = this.latestyear.value.toString();
    //     }
    //     return year_th;
    //   }
    // },
    // latestyear: {
    //   get: function() {
    //     var current = new Date();
    //     var first = new Date(this.firstYear);
    //     var latest_year = current.getFullYear() - first.getFullYear();
    //     return { label: "ปีที่ " + latest_year, value: latest_year };
    //   },
    //   set: function() {}
    // },
    barcodeValue: {
      get: function() {
        var year_th = "";
        if (this.yearthSelected.value < 10) {
          year_th = "0" + this.yearthSelected.value.toString();
        } else {
          year_th = this.yearthSelected.value.toString();
        }

        // console.log(
        //   "barcode value >>>",
        //   year_th +
        //     this.categorySelect.value +
        //     this.textureSelect.value +
        //     this.gender +
        //     this.itemCode3Digit +
        //     this.sizeSelected
        // );

        return (
          year_th +
          this.categorySelect.value +
          this.textureSelect.value +
          this.gender +
          this.itemCode3Digit +
          this.colorSelect.value +
          this.sizeSelected
        );
        // return year_th;
      },
      set: function() {}
    }
  },
  async mounted() {
    this.getAllWebcat();

    // this.editor.content;
    this.pid = this.$route.params.productid;

    // console.log("this.pid  >>>> ", this.pid);

    this.currentUser = JSON.parse(localStorage.getItem("username"));

    this.$vs.loading({ type: "radius" });

    // LOAD get3digitbyorder
    var code3res = null;
    try {
      code3res = await this.$http.get(
        this.$store.state.apiURL + "/api/year/get3digitbylatestyear",
        this.$store.state.auth.apiHeader
      );
    } catch (err) {
      code3res = null;
    }

    // console.log("@ MOUNTED :: this.code3res >>> ", this.code3res);

    if (code3res != null) {
      this.itemCode3DigitTemp = await code3res.data;
    } else {
      this.noticeError("โหลดตัวเลข 3 หลักไม่สำเร็จ");
    }

    // console.log(
    //   "@ MOUNTED :: this.yearthSelected >>> ",
    //   this.yearthSelected,
    //   " itemCode3Digit >>> ",
    //   this.itemCode3Digit
    // );

    // LOAD size

    var sizeres = null;
    try {
      sizeres = await this.$http.get(
        this.$store.state.apiURL + "/api/size",
        this.$store.state.auth.apiHeader
      );
    } catch (err) {
      sizeres = null;
    }
    if (sizeres != null) {
      var res = await sizeres.data.filter(x => x.state === true);
      var list = [];
      for (var p = 0; p < res.length; p++) {
        list[p] = await res[p].code;
      }
      //  console.log("sizes >>>", list);

      this.sizeOptions = await list;
      this.sizeSelected = await list[0];
      //  console.log("this.sizeOption  >>>", this.sizeOptions);

      // this.sizeSelected = this.makeSelect(this.categories[0]);
    } else {
      this.noticeError("โหลดไซส์ไม่สำเร็จ");
    }

    // LOAD COLOR
    var colorres = null;
    try {
      colorres = await this.$http.get(
        this.$store.state.apiURL + "/api/color",
        this.$store.state.auth.apiHeader
      );
    } catch (err) {
      colorres = null;
    }

    if (colorres != null) {
      this.colors = await colorres.data.filter(x => x.state === true);
      this.colorOption = await this.makeOption(this.colors);
      this.colorSelect = await this.makeSelect(this.colors[0]);
    } else {
      this.noticeError("โหลดรหัสสีไม่สำเร็จ");
    }

    // LOAD CATEGORY
    var catres = null;
    try {
      catres = await this.$http.get(
        this.$store.state.apiURL + "/api/category",
        this.$store.state.auth.apiHeader
      );
    } catch (err) {
      catres = null;
    }

    if (catres != null) {
      this.categories = await catres.data.filter(x => x.state === true);
      this.categoryOption = await this.makeOption(this.categories);
      this.categorySelect = await this.makeSelect(this.categories[0]);
    } else {
      this.noticeError("โหลดรหัสประเภทไม่สำเร็จ");
    }

    // LOAD TEXTURE
    var texres = null;
    try {
      texres = await this.$http.get(
        this.$store.state.apiURL + "/api/texture",
        this.$store.state.auth.apiHeader
      );
    } catch (err) {
      texres = null;
    }

    if (texres != null) {
      this.textures = await texres.data.filter(x => x.state === true);
      this.textureOption = await this.makeOption(this.textures);
      this.textureSelect = await this.makeSelect(this.textures[0]);
    } else {
      this.noticeError("โหลดรหัสชนิดไม่สำเร็จ");
    }

    // LOAD YEARTH

    var yearres = null;
    try {
      yearres = await this.$http.get(
        this.$store.state.apiURL + "/api/year",
        this.$store.state.auth.apiHeader
      );
    } catch (err) {
      yearres = null;
    }
    if (yearres !== null) {
      var ylist = [];
      for (var y = 0; y < yearres.data.length; y++) {
        var l = await yearres.data[y].yearOrder;

        var item = await {
          label: "ปีที่ " + l,
          value: l
        };
        ylist.push(item);
      }

      this.yearthOptions = await ylist;
    } else {
      this.noticeError("โหลดรหัสปีไม่สำเร็จ");
    }

    this.barcodeValue = this.getbarcode();

    // LOAD รูปทั้งหมด
    // *** Replace ด้วย reloadImage ด้านล่าง

    // LOAD รายละเอียดชุด

    var prores = null;

    try {
      prores = await this.$http.get(
        this.$store.state.apiURL + "/api/productitem/" + this.pid,
        this.$store.state.auth.apiHeader
      );
    } catch (err) {
      prores = null;
    }

    if (prores !== null) {
      this.itemName = await prores.data.name;
      this.barcodeValue = await prores.data.code;
      // this.categorySelect.value = prores.data.category;

      // EDIT HERE

      // this.categorySelect.value = prores.data.category;

      var categorySelected = await this.categoryOption.find(
        x => x.value === prores.data.category
      );

      if (categorySelected === null || categorySelected === undefined) {
        this.categorySelect = await this.categoryOption[0];
      } else {
        this.categorySelect = await categorySelected;
      }

      var textureSelected = await this.textureOption.find(
        x => x.value === prores.data.texture
      );
      if (textureSelected === null || textureSelected === undefined) {
        this.textureSelect = await this.textureOption[0];
      } else {
        this.textureSelect = await textureSelected;
      }

      var sizeSel = this.sizeOptions.find(x => x === prores.data.size);

      //  console.log("sizeSel >>> ", this.sizeSel);

      if (sizeSel === null || sizeSel === undefined) {
        this.sizeSelected = await this.sizeOptions[0];
        //  console.log("sizeOption[0] >>> ", this.sizeSelected);
      } else {
        this.sizeSelected = await sizeSel;
        //  console.log("sizeSel >>> ", this.sizeSel);
      }

      // this.sizeSelected = prores.data.size;

      if (prores.data.yearth != null && prores.data.yearth != undefined) {
        this.yearthSelected = await {
          label: "ปีที่ " + prores.data.yearth.toString(),
          value: prores.data.yearth
        };
      }

      // if (yearthSel === null || yearthSel === undefined) {
      //   this.yearthSelected = this.yearthOptions[0];
      // } else {
      //   this.yearthSelected = yearthSel;
      // }

      this.theItem = await prores.data;


    }

    // console.log("this.pid >>> ", this.pid);

    // console.log(
    //   "this.categorySelected.value  >>> ",
    //   this.categorySelected.value
    // );
    // console.log("this.textureSelected.value >>> ", this.textureSelected.value);

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // LOAD BRANCH
    var responseBranch;
    try {
      responseBranch = await this.$http.get(
        this.$store.state.apiURL + "/api/branch",
        this.$store.state.auth.apiHeader
      );
      if (responseBranch.status === 200) {
        this.branches = await responseBranch.data.filter(x => x.state === true);
      }
    } catch (error) {
      this.shapeFormat.noticeError(error);
      responseBranch = null;
    }

    // console.log("responseBranch >>> ", responseBranch);

    // LOAD Stock
    var responseStock;
    try {
      responseStock = await this.$http.get(
        this.$store.state.apiURL + "/api/stock/" + this.$route.params.productid,
        this.$store.state.auth.apiHeader
      );

      // console.log("STOCK RESPONSEStock >>> ", responseStock.data);
    } catch (error) {
      //  console.log("responseStock error >>> ", error);
      responseStock = null;
      // appropriately handle the error
    }
    this.thestock = await responseStock.data;

    //  console.log("responseStock >>> ", responseStock);

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //  console.log("responseBranch >>> ", responseBranch);
    var lb = await responseBranch.data;

    // ERROR HERE

    //  console.log("responseStock >>> ", responseStock);

    var ls = await responseStock.data;

    // console.log(" 11111 lb >>>> ", lb);
    // console.log(" 11111 ls >>>> ", ls);

    var loaded = [];
    for (var i = 0; i < lb.length; i++) {
      var st = await ls.find(
        x =>
          x.branchId === lb[i].branchId &&
          x.productItemId === this.$route.params.productid
      );
      // console.log("st >>> ", st.inStock, " [", i, "] ");
      var li = {
        branchId: await lb[i].branchId,
        branchName: await lb[i].branchName,
        productItemId: this.$route.params.productid,
        amount: 0
      };

      if (st) {
        li.amount = await st.inStock;
      }

      await loaded.push(li);
    }

    // console.log("st  >>>>> ", st);

    this.loadedBranch = await loaded;

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    await this.reloadImage();

    this.webitem = await this.theItem.webItem;
    this.bookmarkedItem = await this.theItem.webHotItem;
    this.itemtypeonwebSelected = await this.theItem.webCategory;

    //  console.log();
  },
  components: {
    Prism,
    "v-select": vSelect,
    VueBarcode,
    vueDropzone: vue2Dropzone,
    CurrencyValue,
    TiptapEditor
    // Editor,
    // EditorContent,
    // EditorMenuBar
  },
  // beforeDestroy() {
  //   this.editor.destroy();
  // }
};
</script>

<style>
.smchoose {
  background-color: #4c4c4c !important;
  color: white !important;
}
.clear {
  display: none;
}

.con-img-upload {
  min-height: 150px;
  margin-top: 20px;
  /* margin-top: 170px; */
}
.con-upload {
  width: 45%;
  position: absolute;
}

.con-input-upload {
  width: 95%;
  height: 120px;
  /* position: absolute; */
}

.con-img-upload .img-upload {
  margin: 0 !important;
}

.vue-barcode-element {
  width: 300px !important;
}

.dropzone-custom-content {
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.dropzone.dz-clickable {
  width: 100%;
  border: 1px dashed #4c4c4c;
}

.subtitle {
  color: #314b5f;
}

.imgclass .aura-btn {
  text-align: center;
  margin-left: 20px;
  margin-top: 90px;
  visibility: hidden;
  position: absolute;
  width: 90px;
  height: 30px;
  border: #4c4c4c 1px solid;
  color: white;
  cursor: pointer;
  background-color: #e89999b5;
}

.imgclass .setmain-btn {
  text-align: center;
  margin-left: 20px;
  margin-top: 50px;
  visibility: hidden;
  position: absolute;
  width: 80px;
  height: 30px;
  border: #4c4c4c 1px solid;
  color: black;
  cursor: pointer;
  background-color: #ffffffb5;
}

.imgclass:hover .aura-btn {
  visibility: visible;
}

.imgclass:hover .setmain-btn {
  visibility: visible;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.ck-file-dialog-button {
  display: none;
}

.menubar__button {
  height: 30px;
  padding: 5px 5px !important;
  width: 40px !important;
  background-color: white !important;
  border-radius: 7px;
  cursor: pointer;
  font-size: 11px;
  margin-right: 5px;
}

.editor__content {
  width: 100%;
  margin-top: 20px;
  min-height: 200px;
  border-radius: 7px;
  border: 1px solid #4c4c4c;
}

.vs-input-number--input:-moz-read-only,
.vs-input-number--input:read-only {
  border: 0;
  box-shadow: none;
  background-color: white;
}
</style>
